var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ng-outgest-edge-sport1-website@1.148.0-7b87c033"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// import * as Sentry from '@sentry/nextjs'
// import Config from '@/utils/Config'

// Sentry.init({
//     dsn: Config.SENTRY_DSN,
//     environment: Config.SENTRY_ENVIRONMENT,
//     sampleRate: 0.01,
//     tracesSampleRate: Number(Config.SENTRY_TRACES_SAMPLE_RATE),
//     allowUrls: ['www.sport1.de', 'stage.sport1.de'],
// })
