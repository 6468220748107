import {
    AdPlacementType,
    AdProps,
    AdType,
    ArticleAdProps,
    ArticleSectionType,
    ComponentType,
    LayoutComponentProps,
} from '@sport1/types/web'
import { createAd } from '@/helpers/adHelper'
import {
    canAdSlotBeCreated,
    filterTeaserCardAds,
    getPlacementSuffix,
    isCorrectSize,
    isTeaserType,
} from '@/context/AdPlacementContext/AdPlacementProvider.utils'
import {
    CreateAdSetupProps,
    CreateAdsPlacementsProps,
    ExtraAdSlotProps,
    GetAdComponentsProps,
    MappedAdPlacement,
    PlacementCount,
} from '@/utils/ads/display/createAdsPlacements.types'
import {
    AdPlacement,
    FilterTeaserCardsProps,
} from '@/context/AdPlacementContext/AdPlacementProvider.types'

const STROER_AD_PLACEMENT_ADAPTER = (isMobile: boolean) =>
    ({
        STICKY_FOOTER: isMobile ? 'STICKY_FOOTER' : undefined,
        BANNER: isMobile ? 'TOPMOBILE' : 'BANNER',
        BILLBOARD: isMobile ? undefined : 'BANNER',
        ARTICLE_BOTTOM_AD: 'ARTICLE_BOTTOM_AD',
        EVENT_TOP_AD: isMobile ? 'TOPMOBILE' : 'BANNER',
        EVENT_AD: isMobile ? 'TOPMOBILE' : 'RECTANGLE',
        FULLSCREEN: 'FULLSCREEN',
        MREC: isMobile ? 'TOPMOBILE' : 'RECTANGLE',
        TOPBANNER: 'TOPBANNER',
        VIDEO_AD: 'VIDEO_AD',
        SKYSCRAPER: isMobile ? undefined : 'SKY',
        ARTICLE_SIDE_COLUMN_AD: 'ARTICLE_SIDE_COLUMN_AD',
        INPAGE: isMobile ? undefined : 'POSTERAD',
        VIDEO_CUT_IN_AD: 'VIDEO_CUT_IN_AD',
    }) as const

/**
 * TODO: Ströer
 * TODO: mapping of backend to frontend placements, where to map Ströer?
 * This method requires the mounted frontend.
 * We'll need two mappings, one with mounted frontend, and one that just
 * translates BACKEND to new Ströer placements.
 */
export const getAdPlacement = (
    placement: string,
    variationNumber: number,
    isMobile: boolean
): MappedAdPlacement | undefined => {
    if (placement === AdPlacementType.STICKY_FOOTER && variationNumber === 1) {
        return isMobile ? 'stickyfooter' : undefined
    }

    if (placement === AdPlacementType.BANNER && variationNumber === 1) {
        return isMobile ? 'topmobile' : 'banner'
    }

    if (placement === AdPlacementType.BANNER && variationNumber > 1) {
        return isMobile ? 'topmobile' : undefined
    }

    if (placement === AdPlacementType.SKYSCRAPER) {
        return isMobile ? undefined : 'sky'
    }

    if (placement === AdPlacementType.BILLBOARD) {
        return isMobile ? undefined : 'banner'
    }

    if (placement === AdPlacementType.INPAGE) {
        return isMobile ? undefined : 'posterad'
    }

    if (placement === AdPlacementType.MREC) {
        return isMobile ? 'topmobile' : 'rectangle'
    }

    if (placement === AdPlacementType.EVENT_TOP_AD) {
        return isMobile ? 'topmobile' : 'banner'
    }

    if (placement === AdPlacementType.EVENT_AD) {
        return isMobile ? 'topmobile' : 'rectangle'
    }
}

const getAdComponents = ({
    path,
    layoutComponents /**
     * TODO Stroeer (this is a quick workaround) noShow
     * this prop removes unnecessary ads (related to ad limits on the site)
     */,
}: GetAdComponentsProps): ((AdProps | ArticleAdProps) & ExtraAdSlotProps)[] => {
    const adComponents: (AdProps | ArticleAdProps)[] = []

    layoutComponents?.forEach(component => {
        if (component.type === ComponentType.AD) {
            adComponents.push(component)
        } else if (component.type === ComponentType.ARTICLE_SECTION) {
            const articleAds = component?.content.filter(
                content => content.type === ArticleSectionType.AD
            ) as ArticleAdProps[]
            if (articleAds.length > 0) {
                adComponents.push(...articleAds)
            }
        } else if (isTeaserType(component)) {
            const teaserAds = filterTeaserCardAds(component as FilterTeaserCardsProps)
            if (teaserAds) {
                adComponents.push(...teaserAds)
            }
        }
    })

    if (path && /\/live-ticker\/[a-z0-9_]+\/ticker/.test(path)) {
        adComponents.push(createAd({ placement: AdPlacementType.EVENT_AD }))
    }

    adComponents.push(createAd({ placement: AdPlacementType.STICKY_FOOTER }))

    return adComponents
}

export const placementCounterDefault: PlacementCount = {
    banner: 0,
    posterad: 0,
    rectangle: 0,
    sky: 0,
    topmobile: 0,
    stickyfooter: 0,
}

const createAdsPlacements = ({
    isMobile,
    path = '',
    ads,
    layoutData,
    addMobileId,
    addDesktopId,
}: CreateAdSetupProps): CreateAdsPlacementsProps => {
    const adCounterStroer = {
        TOPMOBILE: 0,
        BANNER: 0,
        ARTICLE_BOTTOM_AD: 0,
        FULLSCREEN: 0,
        RECTANGLE: 0,
        TOPBANNER: 0,
        VIDEO_AD: 0,
        SKY: 0,
        ARTICLE_SIDE_COLUMN_AD: 0,
        POSTERAD: 0,
        VIDEO_CUT_IN_AD: 0,
        STICKY_FOOTER: 0,
    }

    const layoutComponents: LayoutComponentProps[] = ads || layoutData?.components || []

    const placementCounter: PlacementCount = { ...placementCounterDefault }
    let firstBillboard, skyScraper
    const adPlacementIds: string[] = []
    const adPlacementMap: Record<string, AdPlacement> = {}

    // get ad components an array from Layout, ArticleSection, topTeaserCard, TeaserCard, EditorialHeroCard
    const adComponents = getAdComponents({ path, layoutComponents })
    // checks making sure we don't have doubled ads on pages
    const hasTwoAdsAboveFooter =
        layoutComponents[layoutComponents.length - 1]?.type === ComponentType.AD &&
        layoutComponents[layoutComponents.length - 2]?.type === ComponentType.AD
    const hasSingleSkyscraper =
        adComponents?.filter(item => item?.ad?.placement === AdPlacementType.SKYSCRAPER).length ===
        1
    const hasOutbrain = adComponents?.filter(item => item?.ad?.adType === AdType.OUTBRAIN_AD)
    // iterate over ads and generate placements
    adComponents?.forEach(component => {
        const { ad: { placement, adType } = {} } = component
        /** Map the placement from the backend as the adLibs need it (the mapping is different for desktop and mobile)
        We need to map the backend placements to the frontend placements until we have the new adLibs */

        if (
            (adType === 'DISPLAY' || adType === 'TEADS_DISPLAY') &&
            placement &&
            placement in AdPlacementType &&
            isCorrectSize(component, isMobile)
        ) {
            const stroerAdaptedPlacementMap = STROER_AD_PLACEMENT_ADAPTER(isMobile)
            const placementMappedToStroer = stroerAdaptedPlacementMap[placement]

            if (!placementMappedToStroer) return

            adCounterStroer[placementMappedToStroer]++
            const stroerCounter = adCounterStroer[placementMappedToStroer]
            const adPlacement = getAdPlacement(placement, stroerCounter, isMobile)

            if (!adPlacement) return
            /**
             *  It is not beatiful, but works like charm
             *  TODO: it would be nice to refactor this in the future, ideally entire ads setup for stroeer so we dont have to do this kind of weird stuff
             */
            if (
                (hasTwoAdsAboveFooter && hasSingleSkyscraper && adPlacement === 'posterad') ||
                (hasTwoAdsAboveFooter &&
                    adPlacement === 'posterad' &&
                    !path.includes('live-ticker') &&
                    hasOutbrain)
            ) {
                component.noShow = true
                return
            }
            if (!canAdSlotBeCreated(placementCounter, adPlacement)) {
                /**
                 * TODO Stroeer (this is a quick workaround) noShow
                 * this prop removes unnecessary ads (related to ad limits on the site)
                 */
                component.noShow = true
                return
            }
            /**
             * To avoid the case that the restriction of one placement blocked another
             * (for example: topmobile and rectangle)
             */
            component.noShow = false

            placementCounter[adPlacement]++
            const placementCount = placementCounter[adPlacement]

            const suffix = getPlacementSuffix(placementCount)
            // assign id to a component, so we can find our placements during hydration with this id again
            const adId = `${adPlacement}${suffix}`
            component.id = adId
            if (addMobileId) component.mobileId = adId
            if (addDesktopId) component.desktopId = adId

            if (adPlacement === 'banner' && placementCount === 1) {
                firstBillboard = {
                    id: adId,
                    placement: adPlacement,
                    placementId: adId,
                }
            } else if (adPlacement === 'sky' && placementCount === 1) {
                skyScraper = {
                    id: adId,
                    placement: adPlacement,
                    placementId: adId,
                }
            } else {
                adPlacementMap[adId] = {
                    placement: adPlacement,
                    placementId: adId,
                }
            }
            adPlacementIds.push(adId)
        }
    })

    return {
        adPlacementIds,
        adPlacements: {
            firstBillboard,
            skyScraper,
            placements: adPlacementMap,
            placementCounter,
        },
    }
}

export { createAdsPlacements }
