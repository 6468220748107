import 'css/global.css'
import React, { ReactElement, useEffect, useMemo } from 'react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import 'dayjs/locale/de'
import dayjs from 'dayjs'
import dynamic from 'next/dynamic'
import UniversalProvider from '@sport1/news-styleguide/UniversalProvider'
import { LayoutComponentProps, LayoutProps, MetaDataProps } from '@sport1/types/web'
import { onCLS, onLCP, onINP, onFCP, onTTFB } from 'web-vitals/attribution'
import { sendToGoogleAnalytics } from '../utils/webVitals'
import DeepLink from '@/components/DeepLink'
import { Video } from '@/types/video'
import Config from '@/utils/Config'
import { AdPlacementProvider } from '@/context/AdPlacementContext/AdPlacementProvider'
import MediaQueryProvider from '@/utils/breakpoints/MediaQuery'
import ConsentInfoProvider from '@/utils/consent/ConsentInfoProvider'
import getErrorMeta from '@/utils/meta/getErrorMeta'
import getTitle from '@/utils/meta/getTitle'
import { OverlayProvider } from '@/utils/overlay/OverlayProvider'
import { TrackingProvider } from '@/utils/tracking/TrackingProvider'
import RedundantTeaserProvider from '@/utils/content/RedundantTeaserProvider'

// dayjs tz and utc plugins applied twice in _app and _document to init tz on ssr and client for cypress
import extend from '@/utils/dayjs/extensions'
import { createAdsPlacements } from '@/utils/ads/display/createAdsPlacements'
import { sport1Regular } from '@/helpers/fonts'

const AdBlockerHint = dynamic(() => import('@/components/AdBlockerHint'), { ssr: false })
const PianoSupport = dynamic(
    () => import('src/components/ThirdParty/Piano/Composer/components/PianoSupport'),
    { ssr: false }
)

// localize date formats
dayjs.locale('de')
extend()

try {
    if (typeof window !== 'undefined' && localStorage.getItem('ADFree')) {
        Config.ADS_ACTIVE = false
    }
} catch (ignore) {}

interface PageProps {
    ads?: LayoutComponentProps[]
    deeplink?: string
    err?: Error
    layoutData?: LayoutProps
    meta?: MetaDataProps
    statusCode?: number
    video?: Video
}
const App = ({ Component, pageProps }: AppProps<PageProps>): ReactElement => {
    const { ads, deeplink, err, layoutData, statusCode, video } = pageProps
    const { asPath } = useRouter()
    const meta = useMemo(
        () =>
            err instanceof Error || Number(statusCode) >= 400
                ? getErrorMeta({ path: asPath })
                : layoutData?.meta ?? pageProps.meta,
        [asPath, err, pageProps.meta, layoutData, statusCode]
    )
    // In order to improve CLS, we need to know at the start what kind of ads we potentially have (topmobile, rectangle, etc.)
    if (!(false === Config.ADS_ACTIVE || false === layoutData?.meta?.ad?.adsEnabledForLayout)) {
        createAdsPlacements({ isMobile: false, path: asPath, layoutData, addDesktopId: true })
        createAdsPlacements({ isMobile: true, path: asPath, layoutData, addMobileId: true })
    }
    useEffect(() => {
        onCLS(sendToGoogleAnalytics, { reportAllChanges: true })
        onLCP(sendToGoogleAnalytics)
        onINP(sendToGoogleAnalytics, { reportAllChanges: true })
        onFCP(sendToGoogleAnalytics)
        onTTFB(sendToGoogleAnalytics)
    }, [])

    // Passing err to Component is a workaround for
    // https://github.com/vercel/next.js/issues/8592
    return (
        <>
            <Head>
                <title>{getTitle({ layoutProps: layoutData, video })}</title>
            </Head>
            <DeepLink deeplink={deeplink} />
            <UniversalProvider themeName="webLight" isNextJS>
                <ConsentInfoProvider>
                    <RedundantTeaserProvider>
                        <TrackingProvider
                            tracking={meta?.tracking}
                            adTargeting={meta?.ad?.targeting}
                        >
                            <AdPlacementProvider path={asPath} ads={ads} layoutData={layoutData}>
                                <MediaQueryProvider>
                                    <OverlayProvider>
                                        <AdBlockerHint />
                                        <main className={`${sport1Regular.variable} font-sans`}>
                                            <Component {...pageProps} err={err} />
                                        </main>
                                    </OverlayProvider>
                                </MediaQueryProvider>
                            </AdPlacementProvider>
                        </TrackingProvider>
                    </RedundantTeaserProvider>
                </ConsentInfoProvider>
            </UniversalProvider>
            <PianoSupport />
        </>
    )
}

export default App
